import * as React from "react"
import { Box, Text, TextBold } from "../../elements"
import { Colors, Icons } from "../../materials"
export const ColorContrast: React.FC<{
  colorA: string
  colorB: string
  contrast: number
}> = ({ colorA, colorB, contrast }) => (
  <Box display="flex">
    <Box border={`1px solid ${Colors.oc.gray[5]}`} display="flex">
      <Box width={50} height={50} bg={colorA} />
      <Box width={50} height={50} bg={colorB} />
    </Box>
    <Box
      px={2}
      width={50}
      height={50}
      display="flex"
      color={Colors.oc.gray[6]}
      css={{ svg: { width: "100%", height: "auto" } }}
    >
      <Icons.SvgKeyboardArrowRight />
    </Box>

    <Box
      border={`1px solid ${Colors.oc.gray[5]}`}
      bg={Colors.oc.white}
      px={2}
      // flex="1 1 auto"
      width={80}
      height={50}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" alignItems="baseline" justifyContent="center">
        <TextBold fontSize={4} textAlign="center" color={Colors.oc.gray[7]}>
          {contrast}
        </TextBold>
        <Text>:1</Text>
      </Box>
    </Box>
  </Box>
)
