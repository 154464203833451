import * as React from "react"
import { Box, SubHeading, Heading, Text } from "../../elements"
import { Colors } from "../../materials"
import { ColorContrast } from "./ColorContrast"
export const WhatIsContrast: React.FC<{}> = () => (
  <>
    <Heading color={Colors.oc.blue[9]}>What is Contrast?</Heading>
    <Box mt={4} />
    <Box maxWidth="copy">
      <Text>
        Contrast is the difference in brightness between two colors. A good way
        to understand contrast is to compare colors in the same tonality (e.g.:
        grayscale). The closer they are, the lower the contrast between them is.
      </Text>
    </Box>

    <Box mt={[6, 8]} />

    <Box
      display="flex"
      flexDirection={["column", "row"]}
      justifyContent={["center", "center", "flex-start"]}
    >
      {/* COLUMN 1 */}
      <Box
        bg={["transparent", Colors.oc.white]}
        css={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={4}
        pt={4}
        pb={[0, 4]}
      >
        <Box
          bg={Colors.oc.white}
          py={2}
          px={4}
          pt={[4, 2]}
          css={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        >
          <ColorContrast
            colorA={Colors.oc.gray[0]}
            colorB={Colors.oc.black}
            contrast={19.92}
          />
        </Box>
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.oc.gray[0]}
            colorB={Colors.oc.gray[8]}
            contrast={10.91}
          />
        </Box>
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.oc.gray[0]}
            colorB={Colors.oc.gray[6]}
            contrast={3.15}
          />
        </Box>
      </Box>
      {/* COLUMN 2 */}
      <Box
        bg={["transparent", Colors.oc.white]}
        css={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={4}
        pt={[0, 4]}
        pb={4}
      >
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.oc.gray[0]}
            colorB={Colors.oc.gray[4]}
            contrast={1.41}
          />
        </Box>
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.oc.gray[0]}
            colorB={Colors.oc.gray[2]}
            contrast={1.12}
          />
        </Box>
        <Box
          bg={Colors.oc.white}
          py={2}
          pb={[4, 2]}
          px={4}
          css={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <ColorContrast
            colorA={Colors.oc.gray[0]}
            colorB={Colors.oc.gray[1]}
            contrast={1.05}
          />
        </Box>
      </Box>
    </Box>

    <Box mt={[6, 8]} />

    <Box maxWidth="copy">
      <Text>
        Contrast can also be measured between colors in different tonalities.
      </Text>
    </Box>
    <Box mt={6} />

    <Box
      display="flex"
      flexDirection={["column", "row"]}
      justifyContent={["center", "center", "flex-start"]}
    >
      {/* COLUMN 1 */}
      <Box
        bg={["transparent", Colors.oc.white]}
        css={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={4}
        pt={4}
        pb={[0, 4]}
      >
        <Box
          bg={Colors.oc.white}
          py={2}
          px={4}
          pt={[4, 2]}
          css={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        >
          <ColorContrast
            colorA={Colors.tw.blue["200"]}
            colorB={Colors.tw.green["800"]}
            contrast={4.97}
          />
        </Box>
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.tw.blue["200"]}
            colorB={Colors.tw.green["700"]}
            contrast={3.35}
          />
        </Box>
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.tw.blue["200"]}
            colorB={Colors.tw.green["600"]}
            contrast={2.4}
          />
        </Box>
      </Box>
      {/* COLUMN 2 */}
      <Box
        bg={["transparent", Colors.oc.white]}
        css={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={4}
        pt={[0, 4]}
        pb={4}
      >
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.tw.blue["200"]}
            colorB={Colors.tw.green["500"]}
            contrast={1.79}
          />
        </Box>
        <Box bg={Colors.oc.white} py={2} px={4}>
          <ColorContrast
            colorA={Colors.tw.blue["200"]}
            colorB={Colors.tw.green["400"]}
            contrast={1.37}
          />
        </Box>
        <Box
          bg={Colors.oc.white}
          py={2}
          pb={[4, 2]}
          px={4}
          css={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <ColorContrast
            colorA={Colors.tw.blue["200"]}
            colorB={Colors.tw.green["300"]}
            contrast={1.08}
          />
        </Box>
      </Box>
    </Box>
  </>
)
