import * as React from "react"
import {
  LinkAnchor,
  Box,
  SubHeading,
  Text,
  Title,
  Lead,
  Heading,
  TextBold,
} from "../../elements"
import { IconContrast, IconText } from "../../components"
import { Colors } from "../../materials"
import { WhatIsContrast } from "./WhatIsContrast"
export const ContrastAndAccessibility: React.FC<{}> = () => (
  <>
    <Title color={Colors.tw.pink["600"]}>
      Color Contrast and Accessibility
    </Title>
    <Box mt={2} />
    <Box maxWidth="copy">
      <Lead>
        The correct use of color makes content more perceivable to all users. It
        also makes websites more inclusive for people with disabilities.
      </Lead>
    </Box>
    <Box mt={8} />
    <WhatIsContrast />
    <Box mt={[8, 10]} />

    <Heading color={Colors.oc.blue[9]}>Contrast in WCAG</Heading>
    <Box mt={2} />
    <Box display="flex" flexWrap={["wrap", "wrap", "wrap", "nowrap"]}>
      <Box maxWidth="copy">
        <Text>
          <LinkAnchor
            href={"https://www.w3.org/TR/WCAG21/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            WCAG
          </LinkAnchor>{" "}
          (accessibility guidelines) defines 3 levels of accessibility: A, AA
          and AAA. As an example, a website meets AA level if it passes all
          requirements for this level.
        </Text>
        <Box mt={2} />
        <Text>
          When it comes to contrast, there are success criteria for{" "}
          <strong>Text Contrast</strong> (AAA and AA), and for{" "}
          <strong>Non-Text Contrast</strong> (AA only).
        </Text>
        <Box mt={[7, 8, 6, 8]} />
      </Box>

      {/* Super hacky value here. */}
      <Box mr={10} ml={2} display={["none", "none", "none", "block"]} />
      <Box width={[1, 1, "auto"]}>
        <Box
          display="flex"
          flexDirection={["column", "row", "row", "column"]}
          width={1}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" height={30}>
              <IconText color={Colors.oc.black} />
              <TextBold ml={2} fontSize={3}>
                Text Contrast
              </TextBold>
            </Box>
            <Box>
              <Box mt={3} />
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="baseline">
                  <TextBold color={Colors.oc.gray[7]} css={{ width: "3ch" }}>
                    AAA
                  </TextBold>
                  <Box ml={4} />
                  <Box fontSize={2}>
                    <LinkAnchor
                      href="https://www.w3.org/TR/WCAG21/#contrast-enhanced"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      1.4.6 Contrast (Enhanced)
                    </LinkAnchor>
                  </Box>
                </Box>
                <Box mt={1} />
                <Box display="flex" alignItems="baseline">
                  <TextBold color={Colors.oc.gray[7]} css={{ width: "3ch" }}>
                    AA
                  </TextBold>
                  <Box ml={4} />
                  <Box fontSize={2}>
                    <LinkAnchor
                      href="https://www.w3.org/TR/WCAG21/#contrast-minimum"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      1.4.3 Contrast (Minimum)
                    </LinkAnchor>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={[4, 0, 0, 6]} ml={[0, 8, 8, 0]} />
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" height={30}>
              <IconContrast object={Colors.oc.black} />
              <TextBold ml={2} fontSize={3}>
                Non-text Contrast
              </TextBold>
            </Box>
            <Box mt={3} />
            <Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="baseline">
                  <TextBold color={Colors.oc.gray[7]} css={{ width: "3ch" }}>
                    AA
                  </TextBold>
                  <Box ml={4} />
                  <Box fontSize={2}>
                    <LinkAnchor
                      href="https://www.w3.org/TR/WCAG21/#non-text-contrast"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      1.4.11 Non-text Contrast
                    </LinkAnchor>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </>
)
