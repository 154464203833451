import * as React from "react"
import { Box, SubHeading, Text, Label } from "../../elements"
import { Colors } from "../../materials"
import { IconTextBackgroundColor } from "../../components"
export const ButtonsAndLinks: React.FC<{}> = () => (
  <>
    <Box maxWidth="copy" ml={[0, 6, 8, 10]}>
      <SubHeading as="h4" fontSize={[3, 3, 4]} color={Colors.oc.gray[9]}>
        ● Buttons <em>&</em> Links
      </SubHeading>

      <Box mt={2} />
      <Text>
        When buttons and links have text content, the same contrast rule is
        applicable in all their states (e.g.: <em>default</em>, <em>hover</em>,{" "}
        <em>focus</em>, etc). If they don&apos;t have a background color, we
        should use the adjacent background color to measure the text contrast.
      </Text>
    </Box>

    <Box mt={6} />
    <Label textAlign={["left", "left", "right"]}>
      Buttons with Background Color
    </Label>
    <Box mt={[1, 2]} />
    <Box
      ml={[0, 6, 8, 10]}
      display="flex"
      flexWrap="wrap"
      bg={Colors.oc.white}
      border={`2px solid ${Colors.oc.gray[4]}`}
      borderRadius={10}
      py={[3, 0]}
    >
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={0}
          bg={Colors.tw.orange["900"]}
          color={Colors.oc.white}
          fontSize={3}
          borderRadius={4}
        >
          I am a button
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.oc.white}
          backgroundColor={Colors.tw.orange["900"]}
          contrastScore={{
            contrast: 8.93,
            passesAA: true,
            passesAAA: true,
            color1: Colors.oc.white,
            color2: Colors.tw.orange["900"],
          }}
        />
      </Box>
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={0}
          bg={Colors.tw.orange["600"]}
          color={Colors.oc.white}
          fontSize={3}
          borderRadius={4}
        >
          I am a button
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.oc.white}
          backgroundColor={Colors.tw.orange["600"]}
          contrastScore={{
            contrast: 3.39,
            passesAA: false,
            passesAAA: false,
            color1: Colors.oc.white,
            color2: Colors.tw.orange["600"],
          }}
        />
      </Box>
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={0}
          bg={Colors.tw.orange["500"]}
          color={Colors.oc.white}
          fontSize={3}
          borderRadius={4}
        >
          I am a button
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.oc.white}
          backgroundColor={Colors.tw.orange["500"]}
          contrastScore={{
            contrast: 2.55,
            passesAA: false,
            passesAAA: false,
            color1: Colors.oc.white,
            color2: Colors.tw.orange["500"],
          }}
        />
      </Box>
    </Box>
    <Box mt={6} />
    <Label textAlign={["left", "left", "right"]}>
      Buttons with Border only
    </Label>
    <Box mt={[1, 2]} />
    <Box
      ml={[0, 6, 8, 10]}
      display="flex"
      flexWrap="wrap"
      bg={Colors.oc.white}
      border={`2px solid ${Colors.oc.gray[4]}`}
      borderRadius={10}
      py={[3, 0]}
    >
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={`2px solid currentColor`}
          bg={"transparent"}
          color={Colors.tw.pink["900"]}
          fontSize={3}
          borderRadius={4}
        >
          I am a button
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.tw.pink["900"]}
          backgroundColor={Colors.oc.white}
          contrastScore={{
            contrast: 10.07,
            passesAA: true,
            passesAAA: true,
            color1: Colors.tw.pink["900"],
            color2: Colors.oc.white,
          }}
        />
      </Box>
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={`2px solid currentColor`}
          bg={"transparent"}
          color={Colors.tw.pink["600"]}
          fontSize={3}
          borderRadius={4}
        >
          I am a button
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.tw.pink["600"]}
          backgroundColor={Colors.oc.white}
          contrastScore={{
            contrast: 4.27,
            passesAA: false,
            passesAAA: false,
            color1: Colors.tw.pink["600"],
            color2: Colors.oc.white,
          }}
        />
      </Box>
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={`2px solid currentColor`}
          bg={"transparent"}
          color={Colors.tw.pink["400"]}
          fontSize={3}
          borderRadius={4}
        >
          I am a button
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.tw.pink["400"]}
          backgroundColor={Colors.oc.white}
          contrastScore={{
            contrast: 2.32,
            passesAA: false,
            passesAAA: false,
            color1: Colors.tw.pink["400"],
            color2: Colors.oc.white,
          }}
        />
      </Box>
    </Box>

    <Box mt={6} />
    <Label textAlign={["left", "left", "right"]}>Links</Label>
    <Box mt={[1, 2]} />
    <Box
      ml={[0, 6, 8, 10]}
      display="flex"
      flexWrap="wrap"
      bg={Colors.oc.white}
      border={`2px solid ${Colors.oc.gray[4]}`}
      borderRadius={10}
      py={[3, 0]}
    >
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={0}
          bg={"transparent"}
          color={"#0b4c88"}
          fontSize={3}
          borderRadius={4}
          css={{ textDecoration: "underline" }}
        >
          I am also a button (link)
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={"#0b4c88"}
          backgroundColor={Colors.oc.white}
          contrastScore={{
            contrast: 8.74,
            passesAA: true,
            passesAAA: true,
            color1: "#0b4c88",
            color2: Colors.oc.white,
          }}
        />
      </Box>
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={0}
          bg={"transparent"}
          color={Colors.oc.blue[8]}
          fontSize={3}
          borderRadius={4}
          css={{ textDecoration: "underline" }}
        >
          I am also a button (link)
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.oc.blue[8]}
          backgroundColor={Colors.oc.white}
          contrastScore={{
            contrast: 5.02,
            passesAA: true,
            passesAAA: false,
            color1: Colors.oc.blue[8],
            color2: Colors.oc.white,
          }}
        />
      </Box>
      <Box
        width={[1, 1 / 3]}
        py={[3, 6]}
        px={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          px={3}
          py={2}
          border={0}
          bg={"transparent"}
          color={Colors.oc.blue[6]}
          fontSize={3}
          borderRadius={4}
          css={{ textDecoration: "underline" }}
        >
          I am also a button (link)
        </Box>
        <Box mt={3} />
        <IconTextBackgroundColor
          color={Colors.oc.blue[6]}
          backgroundColor={Colors.oc.white}
          contrastScore={{
            contrast: 3.56,
            passesAA: false,
            passesAAA: false,
            color1: Colors.oc.blue[8],
            color2: Colors.oc.white,
          }}
        />
      </Box>
    </Box>
  </>
)
