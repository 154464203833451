import * as React from "react"
import { Footer, Header, ErrorBoundary, SEO } from "../components"
import { SectionCommunity } from "../partials/SectionCommunity"
import {
  Box,
  BoxProps,
  Button,
  Container,
  FormMessage,
  LinkAnchor,
  LinkGatsby,
  Heading,
  Input,
  Lead,
  List,
  ListItem,
  ListOrdered,
  SubHeading,
  Text,
  TextBold,
  Title,
  Tooltip,
  LinkArrowGatsby,
  Pill,
  PillType,
  PillSize,
  Label,
} from "../elements"
import { Icons } from "../materials"
import { FormUrl } from "../partials/button-contrast-check/FormUrl"
import { Colors, Fonts } from "../materials"
import { Theory } from "../partials/contrast-and-accessibility/Theory"
import GatsbyLink from "gatsby-link"

export default function ContrastAndAccessibility() {
  const TITLE = "Contrast and Accessibility - Aditus"
  const DESCRIPTION = `All you need to know about Color Contrast and how it is measured. A guide through Text Contrast and Non-Text Contrast accessibility guidelines from WCAG 2.1.`
  const KEYWORDS = "accessibility, contrast, color, a11y, compliance"
  const URL = "https://www.aditus.io/contrast-and-accessibility/"
  const OG_IMAGE = "https://www.aditus.io/social/contrast-and-accessibility.png"
  const OG_IMAGE_ALT =
    "Text reading 'Contrast and Accessibility' with the Aditus logo next to it"

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}
        keywords={KEYWORDS}
        jsonLd={{
          "@type": "WebPage",
          url: URL,
          name: TITLE,
          description: DESCRIPTION,
        }}
      >
        <meta property="fb:app_id" content="824671391240758" />
        <meta property="og:url" content={URL} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={TITLE} />
        <meta property="og:image" content={OG_IMAGE} />
        <meta property="og:image:alt" content={OG_IMAGE_ALT} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:site_name" content="Aditus" />
        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@aditus_a11y" />
        <meta name="twitter:url" content={URL} />
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content={DESCRIPTION} />
        <meta name="twitter:image" content={OG_IMAGE} />
        <meta name="twitter:image:alt" content={OG_IMAGE_ALT} />
      </SEO>
      <Header />
      <main>
        <ErrorBoundary>
          <Container py={10} maxWidth="m">
            <Theory />
          </Container>
        </ErrorBoundary>
      </main>
      <Box bg={Colors.oc.gray[0]}>
        <Box
          py={[7, 7, 9]}
          bg={Colors.oc.gray[0]}
          borderTop={`1px solid ${Colors.oc.gray[2]}`}
        >
          <Container maxWidth="m">
            <Box display="flex" flexWrap={["wrap", "wrap", "nowrap"]}>
              <Box>
                <LinkGatsby to="/button-contrast-checker/">
                  <Heading
                    color={Colors.oc.blue[9]}
                    css={{
                      display: "inline",
                    }}
                  >
                    Button Contrast Checker
                  </Heading>

                  <Box ml={3} display="inline" css={{ verticalAlign: "super" }}>
                    <Pill type={PillType.MAIN} size={PillSize.MEDIUM}>
                      Free
                    </Pill>
                  </Box>
                </LinkGatsby>

                <Box mt={5} />
                <Box maxWidth="copy">
                  <Lead>
                    Try out our newest tool. Test all buttons on your webpage
                    with just one click.
                  </Lead>

                  <Box mt={3} />
                  <List>
                    <ListItem display="flex" alignItems="flex-start" mb={2}>
                      <Box
                        css={{ marginTop: "6px" }}
                        flex="0 0 auto"
                        width={20}
                        height={20}
                        color={Colors.tw.pink["600"]}
                        mr={3}
                      >
                        <Icons.SvgCheckCircle />
                      </Box>
                      <Text>
                        We test <em>default</em>, <em>hover</em> and{" "}
                        <em>focus</em>.
                      </Text>
                    </ListItem>
                    <ListItem display="flex" alignItems="flex-start" mb={2}>
                      <Box
                        css={{ marginTop: "6px" }}
                        flex="0 0 auto"
                        width={20}
                        height={20}
                        color={Colors.tw.pink["600"]}
                        mr={3}
                      >
                        <Icons.SvgCheckCircle />
                      </Box>
                      <Text>
                        Button background <em>vs.</em> Adjacent background.
                      </Text>
                    </ListItem>

                    <ListItem display="flex" alignItems="flex-start">
                      <Box
                        css={{ marginTop: "6px" }}
                        flex="0 0 auto"
                        width={20}
                        height={20}
                        color={Colors.tw.pink["600"]}
                        mr={3}
                      >
                        <Icons.SvgCheckCircle />
                      </Box>
                      <Text>Save and share results with your team.</Text>
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Box ml={6} mr="auto" />
              <Box mt={[6, 6, 0]}>
                <Box display="flex" alignItems="center">
                  <Box
                    width={42}
                    height={42}
                    css={{ svg: { width: "100%", height: "auto" } }}
                    color={Colors.oc.blue[9]}
                  >
                    <Icons.SvgLoveIt />
                  </Box>
                  <Box flex="1 1 auto" ml={3}>
                    <Label>See Demo</Label>
                    <Box fontSize={4}>
                      <LinkAnchor
                        href="https://www.aditus.io/button-contrast-checker/getbootstrap-com-2019-09-12-at-15-02-11-139"
                        css={{ whiteSpace: "nowrap" }}
                      >
                        Bootstrap Results
                      </LinkAnchor>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* <Text>
          If you have questions, feedback, or want to get in touch,&nbsp;
          <LinkAnchor href="mailto:info@aditus.io?subject=Contrast Check">
            drop us an email.
          </LinkAnchor>
        </Text> */}
          </Container>
        </Box>
        <SectionCommunity
          hideLastestContent
          hideMission
          withHiddenDescription={false}
        />
      </Box>
      <Footer />
    </>
  )
}
