import * as React from "react"
import { Box, SubHeading, Text, TextBold, Label } from "../../elements"
import {
  IconText,
  IconTextLarge,
  IconTextBackgroundColor,
} from "../../components"
import { Colors, Icons } from "../../materials"
import { ContrastScoreBox } from "./ContrastScoreBox"
export const LargeText: React.FC<{}> = () => (
  <>
    <Box ml={[0, 6, 8, 10]}>
      <Box display="flex" flexWrap="wrap" alignItems="flex-start">
        <Box maxWidth={"45ch"}>
          <SubHeading as="h4" fontSize={[3, 3, 4]} color={Colors.oc.gray[9]}>
            ● Large Text
          </SubHeading>

          <Box mt={2} />
          <Text>
            Text is considered large when it has 18pt or more (roughly 24
            pixels) or 14pt bold or more (roughly 19px). Large text needs 3:1
            contrast to pass AA level and 4.5:1 contrast to pass AAA level.
          </Text>
        </Box>
        <Box ml={["auto", "auto", 0, "auto"]} width={[1, 1, 1, "auto"]} />
        <Box
          mt={[4, 4, 4, 0]}
          flex="1 1 auto"
          display="flex"
          justifyContent={["center", "center", "flex-start", "flex-end"]}
        >
          <ContrastScoreBox
            title={() => (
              <>
                Large Text{" "}
                <Text as="span">
                  <em>vs.</em> Background
                </Text>
              </>
            )}
            icon={() => <IconText color={Colors.oc.blue[9]} />}
            scores={[
              {
                score: () => <>3</>,
                suffix: ":1",
                label: () => (
                  <Label
                    fontSize={3}
                    lineHeight={1}
                    color={Colors.oc.blue[9]}
                    as="span"
                  >
                    AA
                  </Label>
                ),
              },
              {
                score: () => <>4.5</>,
                suffix: ":1",
                label: () => (
                  <Label
                    fontSize={3}
                    lineHeight={1}
                    color={Colors.oc.blue[9]}
                    as="span"
                  >
                    AAA
                  </Label>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Box>

    <Box mt={6} />
    <Label textAlign={["left", "left", "right"]}>Example</Label>
    <Box mt={[1, 2]} />
    <Box ml={[0, 6, 8, 10]}>
      <Box
        bg={Colors.oc.white}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        px={4}
        py={4}
        css={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          border: `1px solid ${Colors.oc.gray[9]}`,
        }}
      >
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={"#0b4c88"}
            contrastScore={{
              contrast: 8.74,
              passesAA: true,
              passesAAA: true,
              color1: "#0b4c88",
              color2: "#0b4c88",
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[9]}
            contrastScore={{
              contrast: 6.09,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[9],
              color2: Colors.oc.blue[9],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[8]}
            contrastScore={{
              contrast: 5.02,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[8],
              color2: Colors.oc.blue[8],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[7]}
            contrastScore={{
              contrast: 4.2,
              passesAA: true,
              passesAAA: false,
              color1: Colors.oc.blue[7],
              color2: Colors.oc.blue[7],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[6]}
            contrastScore={{
              contrast: 3.56,
              passesAA: true,
              passesAAA: false,
              color1: Colors.oc.blue[6],
              color2: Colors.oc.blue[6],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[4]}
            contrastScore={{
              contrast: 2.48,
              passesAA: false,
              passesAAA: false,
              color1: Colors.oc.blue[4],
              color2: Colors.oc.blue[4],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[3]}
            contrastScore={{
              contrast: 1.96,
              passesAA: false,
              passesAAA: false,
              color1: Colors.oc.blue[3],
              color2: Colors.oc.blue[3],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[2]}
            contrastScore={{
              contrast: 1.51,
              passesAA: false,
              passesAAA: false,
              color1: Colors.oc.blue[2],
              color2: Colors.oc.blue[2],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            color={Colors.oc.blue[1]}
            contrastScore={{
              contrast: 1.23,
              passesAA: false,
              passesAAA: false,
              color1: Colors.oc.blue[1],
              color2: Colors.oc.blue[1],
            }}
          />
        </Box>
      </Box>
      <Box
        bg={Colors.oc.black}
        border={`1px solid ${Colors.oc.gray[9]}`}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        px={4}
        py={4}
        css={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={"#0b4c88"}
            contrastScore={{
              contrast: 2.4,
              passesAA: false,
              passesAAA: false,
              color1: "#0b4c88",
              color2: "#0b4c88",
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[9]}
            contrastScore={{
              contrast: 3.45,
              passesAA: true,
              passesAAA: false,
              color1: Colors.oc.blue[9],
              color2: Colors.oc.blue[9],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[8]}
            contrastScore={{
              contrast: 4.18,
              passesAA: true,
              passesAAA: false,
              color1: Colors.oc.blue[8],
              color2: Colors.oc.blue[8],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[7]}
            contrastScore={{
              contrast: 5.0,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[7],
              color2: Colors.oc.blue[7],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[6]}
            contrastScore={{
              contrast: 5.9,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[6],
              color2: Colors.oc.blue[6],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[4]}
            contrastScore={{
              contrast: 8.48,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[4],
              color2: Colors.oc.blue[4],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[3]}
            contrastScore={{
              contrast: 10.69,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[3],
              color2: Colors.oc.blue[3],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[2]}
            contrastScore={{
              contrast: 13.87,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[2],
              color2: Colors.oc.blue[2],
            }}
          />
        </Box>
        <Box width={[1 / 3, 1 / 9]} pt={4} pb={3}>
          <IconTextBackgroundColor
            isLarge
            onDark
            color={Colors.oc.blue[1]}
            contrastScore={{
              contrast: 17.01,
              passesAA: true,
              passesAAA: true,
              color1: Colors.oc.blue[1],
              color2: Colors.oc.blue[1],
            }}
          />
        </Box>
      </Box>
    </Box>
  </>
)
