import * as React from "react"
import { Box } from "../../elements"
import { SmallText } from "./SmallText"
import { LargeText } from "./LargeText"
import { ButtonsAndLinks } from "./ButtonsAndLinks"
import { ButtonsWithBackground } from "./ButtonsWithBackground"
import { ButtonsWithBorderOnly } from "./ButtonsWithBorderOnly"
import { ButtonsWithBorderAndBackground } from "./ButtonsWithBorderAndBackground"
import { ContrastAndAccessibility } from "./ContrastAndAccessibility"
import { TextContrast } from "./TextContrast"
import { NonTextContrast } from "./NonTextContrast"

export const Theory: React.FC<{}> = () => (
  <>
    <ContrastAndAccessibility />
    <Box mt={8} />
    <TextContrast />
    <Box mt={[6, 8]} />
    <SmallText />
    <Box mt={[8, 10]} />
    <LargeText />
    <Box mt={[8, 10]} />
    <ButtonsAndLinks />
    <Box mt={[9, 12]} />
    <NonTextContrast />
    <Box mt={[6, 8]} />
    <ButtonsWithBackground />
    <Box mt={[8, 10]} />
    <ButtonsWithBorderOnly />
    <Box mt={[8, 10]} />
    <ButtonsWithBorderAndBackground />
  </>
)
