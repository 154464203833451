import * as React from "react"
import { Box, SubHeading, Text, LinkAnchor, Label } from "../../elements"
import { IconContrast } from "../../components"
import { Colors } from "../../materials"
import { ContrastScoreBox } from "./ContrastScoreBox"
export const NonTextContrast: React.FC<{}> = () => (
  <>
    <Box
      display="flex"
      flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
      alignItems="flex-start"
      m={[-4, -6]}
    >
      <Box p={[4, 6]} flex="0 1 auto" width={[1, "auto"]}>
        <Box maxWidth="copy">
          <SubHeading color={Colors.oc.blue[9]}>Non-Text Contrast</SubHeading>
          <Box mt={2} />
          <Text>
            User interface components need to have a contrast of at least 3:1
            against adjacent backgrounds.
          </Text>
          <Box mt={2} />
          <Text>
            The{" "}
            <LinkAnchor
              href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              guidelines
            </LinkAnchor>{" "}
            are open to interpretation when it comes to what identifies buttons.
          </Text>
          <Box mt={2} />
        </Box>

        <ul>
          <li>
            <Box maxWidth="copy" mb={2}>
              <Text>
                Some people in the community believe that text is enough to
                identify the button as a user interface component. As such,
                having sufficient text contrast is enough.
              </Text>
            </Box>
          </li>
          <li>
            <Box maxWidth="copy">
              <Text>
                Others think that the hit area of a button is fundamental to
                identify it. As such, it should have enough contrast with the
                adjacent background. We are in this group.
              </Text>
            </Box>
          </li>
        </ul>
        <Box maxWidth="copy">
          <Box mt={2} />

          <Box mt={2} />
          <Text>
            Our tool reports buttons without enough contrast with their adjacent
            color as a violation of the Non-Text Contrast rule. We check the
            three most common states: <em>default</em>, <em>normal</em>, and{" "}
            <em>focus</em>.
          </Text>
        </Box>
      </Box>
      <Box
        p={[4, 6, 4, 6]}
        flex="1 1 auto"
        width={[1, "auto"]}
        display="flex"
        justifyContent={["center", "center", "center", "flex-end"]}
      >
        <ContrastScoreBox
          title={() => (
            <>
              Object{" "}
              <Text as="span">
                <em>vs.</em> Background
              </Text>
            </>
          )}
          icon={() => (
            <IconContrast bg={Colors.oc.gray[3]} object={Colors.oc.blue[9]} />
          )}
          scores={[
            {
              score: () => <>3</>,
              suffix: ":1",
              label: () => (
                <Label
                  fontSize={3}
                  lineHeight={1}
                  color={Colors.oc.blue[9]}
                  as="span"
                >
                  AA
                </Label>
              ),
            },
          ]}
        />
      </Box>
    </Box>
  </>
)
