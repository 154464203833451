import * as React from "react"
import { Box, SubHeading, Text } from "../../elements"
import { Colors } from "../../materials"
export const TextContrast: React.FC<{}> = () => (
  <>
    <SubHeading color={Colors.oc.blue[9]}>Text Contrast</SubHeading>
    <Box mt={4} />
    <Box maxWidth="copy">
      <Text>
        In it comes to text (or images of text), there needs to be enough
        contrast between text and background colors. There are two levels of
        acceptable contrast: minimum (AA) and enhanced (AAA). Depending on the
        size of the text, the definition of this levels varies.
      </Text>
    </Box>
  </>
)
