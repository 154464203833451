import * as React from "react"
import { Box, SubHeading, Text, Label } from "../../elements"
import { Colors } from "../../materials"
import { IconObjectBackgroundColor } from "../../components"
export const ButtonsWithBackground: React.FC<{}> = () => (
  <>
    <Box maxWidth="copy" ml={[2, 6, 8, 10]}>
      <SubHeading as="h4" fontSize={[3, 3, 4]} color={Colors.oc.gray[9]}>
        ● Buttons with background
      </SubHeading>

      <Box mt={2} />
      <Text>
        Depending on the adjacent color, the same button might violate the
        non-text contrast rule.
      </Text>
    </Box>

    <Box mt={[6, 8]} />
    <Label textAlign={["left", "left", "right"]}>
      In different adjacent backgrounds
    </Label>
    <Box mt={[1, 2]} />
    <Box ml={[2, 6, 8, 10]}>
      <Box display="flex" flexWrap="wrap" mx={-5}>
        <Box
          width={[1, 1 / 3]}
          px={5}
          py={[3, 0]}
          display="flex"
          flexDirection={["row", "column"]}
          alignItems="center"
        >
          <Box
            border={`2px solid ${Colors.oc.gray[4]}`}
            borderRadius={10}
            bg={Colors.oc.white}
            py={7}
            px={4}
            width={1}
            display="flex"
            justifyContent="center"
          >
            <Box
              px={3}
              py={2}
              border={0}
              bg={Colors.tw.blue["700"]}
              color={Colors.oc.white}
              fontSize={3}
              borderRadius={4}
            >
              I am a button
            </Box>
          </Box>

          <Box mt={[0, 5]} ml={[5, 0]} />
          <Box
            width={[100, "auto"]}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconObjectBackgroundColor
              objectBackgroundColor={Colors.tw.blue["700"]}
              containerBackgroundColor={Colors.oc.white}
              contrastScore={{
                contrast: 5.4,
                passesAA: true,
                passesAAA: false,
                color1: Colors.oc.white,
                color2: Colors.tw.blue["700"],
              }}
            />
          </Box>
        </Box>
        <Box
          width={[1, 1 / 3]}
          px={5}
          py={[3, 0]}
          display="flex"
          flexDirection={["row", "column"]}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            border={`2px solid ${Colors.oc.gray[4]}`}
            borderRadius={10}
            bg={Colors.oc.gray[4]}
            py={7}
            px={4}
            width={1}
            display="flex"
            justifyContent="center"
          >
            <Box
              px={3}
              py={2}
              border={0}
              bg={Colors.tw.blue["700"]}
              color={Colors.oc.white}
              fontSize={3}
              borderRadius={4}
            >
              I am a button
            </Box>
          </Box>

          <Box mt={[0, 5]} ml={[5, 0]} />
          <Box
            width={[100, "auto"]}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconObjectBackgroundColor
              objectBackgroundColor={Colors.tw.blue["700"]}
              containerBackgroundColor={Colors.oc.gray[4]}
              contrastScore={{
                contrast: 3.62,
                passesAA: true,
                passesAAA: true,
                color1: Colors.oc.gray[4],
                color2: Colors.tw.blue["700"],
              }}
            />
          </Box>
        </Box>
        <Box
          width={[1, 1 / 3]}
          px={5}
          py={[3, 0]}
          display="flex"
          flexDirection={["row", "column"]}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            border={`2px solid ${Colors.oc.gray[4]}`}
            borderRadius={10}
            bg={Colors.oc.gray[7]}
            py={7}
            px={4}
            width={1}
            display="flex"
            justifyContent="center"
          >
            <Box
              px={3}
              py={2}
              border={0}
              bg={Colors.tw.blue["700"]}
              color={Colors.oc.white}
              fontSize={3}
              borderRadius={4}
            >
              I am a button
            </Box>
          </Box>
          <Box mt={[0, 5]} ml={[5, 0]} />
          <Box
            width={[100, "auto"]}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconObjectBackgroundColor
              objectBackgroundColor={Colors.tw.blue["700"]}
              containerBackgroundColor={Colors.oc.gray[7]}
              contrastScore={{
                contrast: 1.5,
                passesAA: false,
                passesAAA: false,
                color1: Colors.oc.gray[7],
                color2: Colors.tw.blue["700"],
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  </>
)
