import * as React from "react"
import { Box, TextBold, Label } from "../../elements"
import { Icons, Colors } from "../../materials"

export const ContrastScoreBox: React.FC<{
  title: () => React.ReactNode
  icon: () => React.ReactNode
  scoreSpacing?: number
  color?: string
  scores: {
    score: () => React.ReactNode
    suffix: string
    label: () => React.ReactNode
    labelPosition?: "right" | "bottom"
  }[]
}> = ({ title, icon, scores, scoreSpacing = 6, color = Colors.oc.blue[9] }) => (
  <Box
    py={[4, 5]}
    px={[4, 5]}
    as="p"
    border={`2px solid ${color}`}
    borderRadius={8}
  >
    <Box
      as="span"
      pb={5}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <TextBold as="span" color={color} css={{ whiteSpace: "nowrap" }}>
        {title()}
      </TextBold>
      <Box as="span" ml={4} display={["block", "none"]} />
      <Box
        as="span"
        width={[30, 40]}
        display={["none", "block"]}
        height={[30, 40]}
        mx={4}
        color={color}
      >
        <Icons.SvgKeyboardRight />
      </Box>

      {icon()}
    </Box>
    <Box as="span" display="flex" justifyContent="center" mx={-scoreSpacing}>
      {scores.map(
        ({ score, suffix, label, labelPosition = "right" }, index) => (
          <Box as="span" px={scoreSpacing} key={index} flex="0">
            <Box
              as="span"
              display="flex"
              {...(labelPosition === "right"
                ? {}
                : { flexWrap: "wrap", justifyContent: "center" })}
              alignItems="baseline"
            >
              <TextBold as="span" fontSize={8} lineHeight={1} color={color}>
                {score()}
              </TextBold>
              <TextBold as="span" fontSize={4} lineHeight={1} color={color}>
                {suffix}
              </TextBold>
              {labelPosition === "right" ? (
                <>
                  <Box as="span" mr={1} />
                  {label()}
                </>
              ) : (
                <>
                  <Box width={1} display="flex" justifyContent="center">
                    {label()}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )
      )}
    </Box>
  </Box>
)
